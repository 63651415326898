const config = {
  title: "RadarBi",
  tenant: process.env.REACT_APP_APP,
  favicon: "favicon.png",
  pages: {
    login: {
      logo: {
        url: "logo.png",
      },
      background: "white",
      text: "black",
      button: {
        background: "#39B54A",
        text: "white",
      },
      sideContent: {
        background: "#1C2127",
      },
    },
    home: {
      header: {
        logo: {
          src: "logo.png",
          width: 124,
          height: 28,
        },
        avatar: {
          src: "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png",
          height: 28,
        },
        toggleSideBarMenu: {
          open: "left.svg",
          closed: "right.svg",
          background: "#fff",
        },
        colors: {
          background: "#fff",
          text: "#1C2127",
        },
      },
      sidebar: {
        colors: {
          text: "#1C2127",
          hover: {
            background: "#CDECD1",
            text: "#39B54A",
          },
          active: {
            background: "#fff",
            text: "#1C2127",
          },
          subMenu: {
            selected: {
              background: "#fff",
            },
          },
        },
        loader: {
          color: "#39B54A",
        },
      },
      footer: {
        text: "2024 @ RadarBI",
        links: {
          about: "https://www.radarbi.com/",
          team: "https://www.upwork.com/freelancers/~014ed9c803f2812feb",
        },
        colors: {
          text: "#1C2127",
          background: "#fff",
        },
      },
      loader: {
        color: "black",
        size: 28,
      },
    },
  },
};

export default config;
