import { useAuth0 } from "@auth0/auth0-react";
import { Button, Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthWrapper, ButtonWrapper } from "./style";
import config from "../../../../config/config";

const { app } = config
function SignIn() {
  const { loginWithRedirect } = useAuth0();

  const login = async () =>
    loginWithRedirect({
      authorizationParams: { audience: process.env.REACT_APP_AUTH0_AUD },
    });

  const history = useHistory();
  const [form] = Form.useForm();

  const { loading, error } = useSelector((state) => state.data);

  if (error) {
    toast.error(error);
  }

  const handleSubmit = async (values) => {
    login();
    // dispat'ch(Adminlogin(values));
  };

  const handleClick = () => {
    history.push("/contact-us");
  };

  return (
    <AuthWrapper>
      {/* <p className="auth-notice">
        Don&rsquo;t have an account? <NavLink to="#">Sign up now</NavLink>
      </p> */}
      <div className="auth-contents login-form"
        style={{
          backgroundColor: app.pages.login.background,
        }}
      >
        <Form
          name="login"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <h3 as="H3" className="text-center auth-title"
            style={{
              '--primary-text-color': app.pages.login.text,
            }}
          >
            Sign in to <span className="color-success text-center" style={{
              color: app.pages.login.button.background,
              textTransform:"capitalize"
            }}>{app.title}</span>
          </h3>

          <Form.Item>
            <ButtonWrapper>
              <Button
                className="btn-signin"
                htmlType="submit"
                type="primary"
                size="large"
                style={{
                  '--btn-text-color': app.pages.login.button.text,
                  '--btn-primary-color': app.pages.login.button.background,
                }}
              >
                {loading ? "Loading..." : "Sign In"}
              </Button>
            </ButtonWrapper>
          </Form.Item>
        </Form>

        <span onClick={handleClick} style={{
          color: app.pages.login.text,
          textDecoration: "underline",
          cursor:"pointer"
        }}>
          Contact Us
        </span>
      </div>
    </AuthWrapper>
  );
}

export default SignIn;
