import { darkTheme, theme } from "./theme/themeVariables";

const appConfigs = require(`./tenants/${process.env.REACT_APP_APP}.js`).default;

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  app: appConfigs,
};

export default config;