import { Col, Row } from "antd";
import React from "react";
import { Aside, Content } from "./overview/style";
import config from "../../../config/config";

const {app} = config
const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24}>
          <Aside>
            <div className="auth-side-content" style={{
              background: app.pages.login.sideContent.background
            }}>
              <img
                src={require(`../../../static/img/auth/topShape.png`)}
                alt=""
                className="topShape"
              />
              <img
                src={require(`../../../static/img/auth/bottomShape.png`)}
                alt=""
                className="bottomShape"
              />
              <Content>
                <img
                  className="auth-content-figure"
                  src={require(`../../../static/tenants/${app.tenant}/welcome/${app.pages.login.logo.url}`)}
                  alt=""
                  height={app.pages.login.logo.height}
                  width={app.pages.login.logo.width}
                />
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
