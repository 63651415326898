import React, { useEffect } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAuth0 } from "@auth0/auth0-react";
import { ConfigProvider, Spin } from "antd";
import "antd/dist/antd.less";
import Cookies from "js-cookie";
import { hot } from "react-hot-loader/root";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { Adminlogin, fetchDahboard } from "./config/api/thunkapi.js";
import config from "./config/config.js";
import ContactUs from "./container/contact-us";
import Dashboard from "./container/dashboard";
import AuthLayout from "./container/profile/authentication/Index";
import SignIn from "./container/profile/authentication/overview/SignIn.js";
import withAdminLayout from "./layout/withAdminLayout.js";
import { apiDashboardCallSuccess } from "./redux/dashboard/dashboardReducer.js";
import { setViewsResults, setWorkbooks } from "./redux/sidebar/sidebar.js";
import store from "./redux/store.js";
import "./static/css/style.css";
const { theme, app } = config;

function ProviderConfig() {
  const { isAuthenticated } = useAuth0();
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Route
          path="/"
          exact
          component={
            isAuthenticated ? withAdminLayout(Dashboard) : AuthLayout(SignIn)
          }
        />
        <Route path="/login/callback" exact component={LoginWithApi} />
        {/* <Route path="/redirect" exact component={LoginWithApi} /> */}
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/view/:id" component={withAdminLayout(Dashboard)} />
        <Route exact path="/social" component={withAdminLayout(Dashboard)} />
        <ToastContainer />
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  document.title = app.title;
  useEffect(() => {
    const changeFavicon = (url) => {
      let link = document.querySelector("link[rel*='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = url;
    };

    changeFavicon(require(`./static/tenants/${app.tenant}/${app.favicon}`));
  }, []);

  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <ProviderConfig style={{ marginTop: "50px" }} />
      </Router>
    </Provider>
  );
}

export default hot(App);

const LoginWithApi = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        Cookies.set("token", accessToken);
        if (accessToken) {
          await Adminlogin({ token: accessToken });
          const site = Cookies.get("site");
          if (site) {
            const data = await fetchDahboard(site);
            dispatch(setViewsResults(data?.viewsResults));
            dispatch(setWorkbooks(data?.workbooks));
            dispatch(apiDashboardCallSuccess(data));
          }
          history.replace("/");
        }
      } catch (error) {
        console.error("Error in authentication or fetching data:", error);
        history.replace("/");
      }
    };
    fetchData();
  }, [dispatch, getAccessTokenSilently, history]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Spin style={{
        '--hover-text-color': app.pages.home.sidebar.loader.color
      }} size="large" />
    </div>
  );
};
