import axios from "axios";
import Cookies from "js-cookie";
import {
  apiCallFailure,
  apiCallStart,
  apiCallSuccess,
} from "../../redux/users/dataReducer";
import { API } from "./apiConfig";

function getClient() {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
      "X-Tableau-Auth": Cookies.get("tableau_token"),
    },
  });
}

export const Adminlogin = async (payload) => {
  const client = getClient();
  const { data } = await client.post(`${API.auth.login}`, payload);
  Cookies.set("tableau_token", data.token);
  Cookies.set("embed_token", data.embed_token);
  Cookies.set("site", data.site);
  return data;
};

export const fetchDahboard = async (siteId) => {
  const client = getClient();
  const response = await client.get(`${API.dashboard.token}/${siteId}`);
  return response.data?.data;
};

export const submitContactForm = (formData) => async (dispatch) => {
  dispatch(apiCallStart());
  try {
    const client = getClient();
    const response = await client.post(`/contact-us`, formData);
    dispatch(apiCallSuccess(response.data));
    return { success: true, message: response?.data?.message };
  } catch (error) {
    dispatch(
      apiCallFailure(error.response?.data?.message || "An error occurred")
    );
    return {
      success: false,
      error: error.response?.data?.data?.errors[0]?.msg || "An error occurred",
    };
  }
};
