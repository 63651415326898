import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logOut } from "../../../redux/authentication/actionCreator";
import { Popover } from "../../popup/popup";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import config from "../../../config/config";
const { app } = config;

function AuthInfo() {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const [state, setState] = useState({
    flag: "english",
  });

  const SignOut = (e) => {
    e.preventDefault();
    Cookies.remove("token");
    Cookies.remove("embed_token");
    Cookies.remove("site");
    dispatch(logOut());
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/contact-us">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" to="#" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value) => {
    setState({
      ...state,
      flag: value,
    });
  };

  const country = (
    <NavAuth>
      <Link onClick={() => onFlagChangeHandle("english")} to="#">
        <img src={require("../../../static/img/flag/english.png")} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("germany")} to="#">
        <img src={require("../../../static/img/flag/germany.png")} alt="" />
        <span>Germany</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("spain")} to="#">
        <img src={require("../../../static/img/flag/spain.png")} alt="" />
        <span>Spain</span>
      </Link>
      <Link onClick={() => onFlagChangeHandle("turky")} to="#">
        <img src={require("../../../static/img/flag/turky.png")} alt="" />
        <span>Turky</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={app.pages.home.header.avatar.src} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
